import './public-path-ACW';

import 'focus-visible/dist/focus-visible.min.js';
import preload from './preload';
import { delegate, sticky } from 'tippy.js';
import { headerModule } from './header/headerModule';
import { cartModule } from './header/cartModule';
import { cleanup, navData, topBarContent } from './helpers/dataExtractor';
import { domHelpers, eventHelpers } from './helpers/helpers';
import {
    AnalyticInteractions,
    GA_eventActions,
    GA_eventCategories,
    GA_EventValues,
	linkOpen,
} from './helpers/AnalyticHelper';
import pageScriptImport from './loadPageScripts';
import { iterable } from './helpers/iterable';
import './helpers/sentry';
import { PED_PhoneMask } from './modules/masks';

interface cartAjaxResponse {
	display?: string;
	price?: string;
	products?: Product[];
	myaccount?: string[];
	firstname?: string[];
	shipping?: Shipping;
	zip?: null;
	region_code?: null;
}

interface Product {
	id: number;
	full_id: string;
	name: string;
	image: string;
	model: string;
	price: string;
	new_price_coupon: null;
	prod_discount: null;
	coupons_code_type: null;
	subscription: string;
	subFrequency: number;
	quantity: number;
}

interface Shipping {
	threshold: boolean;
}

(async function main() {
    await preload();
    await import('../app/App'); // load react components
	new pageScriptImport();
	setPageEvents();
	initializeHeader();
	iterableIntegration();
	initTooltips();
	initTealiumAnalytics();
	eventHelpers.initResponsiveLazyImages();
	await import('bootstrap').then((bootstrap) => {
        window.bootstrap = bootstrap;
	});
	await import('./page/footer');
})();

function stringValue(test: string) {
	// helper to always return a string
	if (test && test != '') {
		return test;
	}
	return '';
}

function iterableIntegration() {
	// we have a custom node supplying some php global values called s-i
	let iterableElement: HTMLElement = document.querySelector('s-i');
	const key = iterableElement?.dataset.info;
	const sId = iterableElement?.dataset.sid;

	// if we have an iterable key, initialize our integration
	if (key) {
		if (iterable.initialize(key, sId)) {
			// we can check the page type with this meta tag
			let typeMeta: HTMLMetaElement = document.querySelector('meta[property="og:type"]');
			let page = '';
			if (typeMeta) {
				page = typeMeta.getAttribute('content');
			}
			typeMeta = null; // cleanup our variable

			// we want to track article and product page views in iterable
			if (page == 'article' || window.pageInfo) {
				let productInfo: { id?: string; name?: string; image?: string } = {};
				let category = 'Article';
				// product pages have
				if (window.pageInfo) {
					productInfo.id = stringValue(window.pageInfo.pid);
					productInfo.name = stringValue(window.pageInfo.pname);
					category = stringValue(window.pageInfo.cat_name) != '' ? window.pageInfo.cat_name : stringValue(window.pageInfo.cat_id);
				}

				// dispatch the tracking event
				document.body.dispatchEvent(
					new CustomEvent('iterablePageViewEvent', {
						detail: {
							category: category,
							productData: productInfo,
						},
					})
				);
			}
		}
	}
	// cleanup
	iterableElement.parentElement.removeChild(iterableElement);
	iterableElement = null; // mem cleanup
}

function initTooltips() {
	const tooltips = delegate('body', {
		target: '.tooltipnew img, .tooltipnew > span:first-of-type',
		onCreate: function (instance) {
			// let's target the close button the the popup and give it a proper event
			const closeBtn = instance.popper.querySelector('.tooltipClose') as HTMLButtonElement;

			if (closeBtn) {
				closeBtn.onclick = (e: Event) => {
					e.preventDefault();
					instance.hide();
				};
			}
		},
		onHidden: function (instance) {
			// once we've hidden the tooltip, return our focus back to the element that invoked the tooltip
			(instance.reference as HTMLElement).focus();
		},
		onMount: function (instance) {
			const titleID: string = instance.reference.closest('.tooltipnew').querySelector('header').textContent.replace(/ /g, '') + Math.random().toString();
			instance.popper.id = titleID;
			instance.reference.setAttribute('aria-labeledby', titleID);
		},
		onShown: function (instance) {
			const titleID: string = instance.reference.closest('.tooltipnew').querySelector('header').textContent.replace(/ /g, '') + Math.random().toString(), // generate tooltip ID for aria linking
				parent = instance.popper.querySelector('.tippy-content') as HTMLElement; // get reference to the text element within the tooltip

			instance.popper.id = titleID; // set the tooltip's ID
			parent.tabIndex = 0;
			(parent.querySelector('header + span') as HTMLSpanElement).focus(); // focus the text so reader will read it

			domHelpers.trapFocus(parent); // trap focus in tooltip, can only exit on with button or escape

			parent.onkeyup = (event: KeyboardEvent) => {
				event.preventDefault();
				if (event.key == 'Escape') {
					instance.hide();
				}
			};
		},
		onShow: function (instance) {
			// if we got here using any key other than enter, cancel the tooltip by returning false
			if (instance.popper.dataset.cancelOpen == 'true') {
				return false;
				instance.hide();
			}
		},
		onTrigger: function (instance, event) {
			// logic so the onShow can know if it was the Enter key that invoked the tooltip or another key
			// this is because using 'click' caused two events to fire, on both up and down
			instance.popper.dataset.cancelOpen = 'false';
			if ('key' in event) {
				const key = (event as KeyboardEvent).key;
				if (key != 'Enter') {
					// if we pressed a key other than enter, ignore it
					instance.popper.dataset.cancelOpen = 'true';
				}
			}

			if (instance.state.isShown) {
				instance.popper.dataset.cancelOpen = 'true';
				instance.hide();
			}
		},
		content: function (ref) {
			// DOM element references
			const parent = ref.closest('.tooltipnew');
			if (parent) {
				const textNode = parent.querySelector('.tooltiptextnew');

				// here we're pulling our tooltip inner HTML from the DOM node into the tooltip instance
				return textNode.innerHTML;
			}
		},
		arrow: false, // whether or not to show an arrow on the tooltip
		aria: 'labelledby', // what ARIA attribute to use
		maxWidth: 280, // max tooltip width
		placement: 'bottom', // default tooltip placement relative to the element that called it
		distance: 7, // distance from tooltip to element that called it
		theme: 'PED_toolTip', // CSS theme to apply to the tooltip, see shared_source\scss\components\_toolTips.scss
		interactive: true, // whether or not you can interact with elements inside the tooltip
		sticky: true, // keep it attached to the element that called it when the page moves
		flipOnUpdate: false, // whether or not it flips when it gets close to the edge
		flip: false, // whether or not it flips on open to keep whole thing visible
		appendTo: document.body, // append to document body
		// hideOnClick: false,      // uncomment to keep tooltip open for CSS debugging
		trigger: 'click keyup', // what events trigger the tooltip
		plugins: [sticky],
	});
}

function initTealiumAnalytics() {
	// add our custom event handlers to document
	const analytics = new AnalyticInteractions('ACW Page Event', false);

	const getSelectText = function (e: HTMLSelectElement) {
			const index = e.selectedIndex;
			const selectLabel = e.getAttribute('aria-label') ? e.getAttribute('aria-label') : e.options[0].innerText.trim();

			// build a string based off aria label and the selected option's text
			if (e.options.length > 0) {
				const eText = e.options[index].innerText.trim();
				if (selectLabel != eText) {
					return `${selectLabel} [${eText}] `;
				} else {
					return '';
				}
			} else {
				return '';
			}
		},
		quickSearchForm = document.querySelector('form[name="quick_search"]') as HTMLFormElement,
		miniSplitSizingTool = document.querySelector('#wizard-app') as HTMLDivElement;

	// ACW specific events
	// header search submit
	document.querySelector('#searchForm')?.addEventListener('submit', function searchEvent(e: Event) {
		e.preventDefault();
		const searchForm = e.target as HTMLFormElement,
			searchBox = searchForm.querySelector('#searchText') as HTMLInputElement;

		AnalyticInteractions.tealium_searchSubmit(searchBox.value, GA_eventActions.click, GA_eventCategories.ACWHeader, 'Search: ' + searchBox.value, GA_EventValues.SearchSubmit.toString(), () => {
			searchForm.submit();
		});
	});

	// quick search hook
	quickSearchForm?.addEventListener(
		'submit',
		function quickSearchEvent(e: Event) {
			e.preventDefault(); // we'll manually submit this after our analytic callback event

			const quickSearchForm = e.target as HTMLFormElement,
				selectBoxes = quickSearchForm.querySelectorAll('select') as NodeListOf<HTMLSelectElement>;
			let outputText: string = '';

			// iterate all of our select boxes, determine a human-readable string from them
			for (let index = 0; index < selectBoxes.length; index++) {
				const element = selectBoxes[index] as HTMLSelectElement;

				if (element.offsetParent != null && !element.disabled) {
					// use this test to see if it is visible in the DOM (via display) and enabled
					outputText += getSelectText(element);
				}
			}
			if (outputText) {
				//console.log(`Quick Search Event: ${outputText.trim()}`);
				quickSearchForm.dataset.eventLabel = `Quick Search Event: ${outputText.trim()}`;
			} else {
				//console.log(`Quick Search Event Triggered, nothing selected.`);
				quickSearchForm.dataset.eventLabel = `Quick Search Event Triggered, nothing selected.`;
			}
			AnalyticInteractions.tealium_productFilter(GA_eventActions.click, GA_eventCategories.Body, quickSearchForm.dataset.eventLabel, '0', () => quickSearchForm.submit());
		},
		true
	);

	// article tools / mini split sizing guide
	const articleToolsAnalytics = (ev: MouseEvent) => {
		const ele = ev.target as HTMLElement;
		const assocLabel = miniSplitSizingTool.querySelector(`label[for='${ele.id}']`) as HTMLLabelElement;

		if (ele.dataset.eventAction) {
			ele.dataset.eventAction = ele.dataset.eventAction.toLowerCase();

			if (ele.dataset.eventAction == 'change') {
				ele.dataset.eventLabel = (ele as HTMLSelectElement).options[(ele as HTMLSelectElement).selectedIndex].text;
			}
			if (ele.dataset.eventAction == 'blur') {
				ele.dataset.eventLabel = (ele.dataset.eventDesc ? ele.dataset.eventDesc + ' - ' : '') + (ele as HTMLInputElement).value;
			}

			if (assocLabel) {
				const endIndex = assocLabel.innerText.lastIndexOf(' ?') > 0 ? assocLabel.innerText.lastIndexOf('?') : assocLabel.innerText.length,
					labelText = assocLabel.innerText.substr(0, endIndex).trim();
				ele.dataset.eventLabel = `${labelText}: ${ele.dataset.eventLabel}`;
			}

			ele.dispatchEvent(analytics.analyticEvent);
		}
	};
	miniSplitSizingTool?.addEventListener('click', articleToolsAnalytics);
	miniSplitSizingTool?.addEventListener('blur', articleToolsAnalytics);
	miniSplitSizingTool?.addEventListener('change', articleToolsAnalytics);
}

function initializeHeader() {
	const headerLogoLink = document.querySelector('#header .headerLogo a') as HTMLAnchorElement,
		callLink = document.querySelector('#header .phoneDisplay a') as HTMLAnchorElement,
		helpLink = document.querySelector('#header .helpLink a') as HTMLAnchorElement;
		var accountLink = document.querySelector('#header .myAccount button') as HTMLAnchorElement;
		var mobileAccountLink = document.querySelector('#mobilePersonalStatic .changeLoginDialog') as HTMLAnchorElement;

	if (navData) {
		const headerPhoneArea = document.querySelector('.headerPhone'),
			headerPhoneDisplay = headerPhoneArea.querySelector('.phoneDisplay') as HTMLElement,
			headerPhonePipe = headerPhoneArea.querySelector('.pipe') as HTMLElement,
			headerPhoneLink = headerPhoneDisplay.querySelector('a') as HTMLAnchorElement,
			headerPhoneSpan = headerPhoneLink.querySelector('.phoneNumber') as HTMLSpanElement;

		document.body.addEventListener('headerIndexAdjust', () => {
			// this event makes sure header is behind new nav when opened
			const headerMain = document.querySelector('#headerMain') as HTMLDivElement;
			if (headerMain.style.zIndex) {
				// remove the style we applied when opening nav
				// wait a moment for transitions to finish
				setTimeout(() => {
					headerMain.style.zIndex = '';
				}, 300);
			} else {
				// add the style when opening nav
				headerMain.style.zIndex = '4';
			}

			const adminBar = document.getElementById('productAdminBarContent') as HTMLDivElement;
			if (adminBar) {
				if (adminBar.style.zIndex) {
					// remove the style we applied when opening nav
					// wait a moment for transitions to finish
					setTimeout(() => {
						adminBar.style.zIndex = '';
					}, 300);
				} else {
					// add the style when opening nav
					adminBar.style.zIndex = '2001';
				}
			}
		});

		const loginToken = navData.loginToken;

		headerPhoneLink.href = 'tel: ' + navData.phoneNumber;
		if (navData.phoneNumber) {
			headerPhoneSpan.textContent = 'Call ' + navData.phoneNumber;
		} else {
			// delete phone number and pipe character
			headerPhoneDisplay.style.display = 'none';
			headerPhonePipe.style.display = 'none';
		}

		new headerModule(null, topBarContent, loginToken);

		// const footer = new footerModule(
		//     JSON.parse(navData.siteLinkJSON),
		//     JSON.parse(navData.storeLinkJSON),
		//     JSON.parse(navData.socialLinkJSON),
		//     JSON.parse(navData.miscLinkJSON),
		//     navData.expertJSON,
		//     navData.bbbJSON
		// );

		// remove data div from dom
		cleanup();
		const isLegacy = Boolean(document.body.dataset?.legacy == '1');

		if (isLegacy) {
            window.headerCartModule = new cartModule('persCart' + document.body.dataset.sid);
        }

		window.helpers = {
			trapFocus: domHelpers.trapFocus,
			lazyLoad: eventHelpers.initResponsiveLazyImages,
			isSmallScreen: domHelpers.isSmallScreen,
		};
		window.PED_masks = {
			phoneMask: PED_PhoneMask,
		};
		window.CLS_Debug = {
			toggle: () => {
				const CLS = document.getElementById('CLS');
				if (CLS) {
					if (CLS.style.display === 'none') {
						CLS.style.display = '';
					} else {
						CLS.style.display == 'none';
					}
				}
			},
		};

		// add some additional analytic interactions not covered by previous modules
		// call us link
		callLink.addEventListener('click', (event: MouseEvent) => {
			// event.preventDefault();
			let openMethod: linkOpen = linkOpen.self;
			if (callLink.target == '_blank' || event.shiftKey) openMethod = linkOpen.newWindow;
			if (event.ctrlKey) openMethod = linkOpen.newTab;

			AnalyticInteractions.headerEvent(GA_eventActions.click, 'Header Call Link', GA_EventValues.HeaderCall, callLink, openMethod);
		});
		// help click
		helpLink.addEventListener('click', (event: MouseEvent) => {
			// event.preventDefault();
			let openMethod: linkOpen = linkOpen.self;
			if (helpLink.target == '_blank' || event.shiftKey) openMethod = linkOpen.newWindow;
			if (event.ctrlKey) openMethod = linkOpen.newTab;
			AnalyticInteractions.headerEvent(GA_eventActions.click, 'Header Help Link', GA_EventValues.HeaderHelp, helpLink, openMethod);
		});
		// logo click
		headerLogoLink.addEventListener('click', (event: MouseEvent) => {
			// event.preventDefault();
			let openMethod: linkOpen = linkOpen.self;
			if (headerLogoLink.target == '_blank' || event.shiftKey) openMethod = linkOpen.newWindow;
			if (event.ctrlKey) openMethod = linkOpen.newTab;
			AnalyticInteractions.headerEvent(GA_eventActions.click, 'Logo Home Page Link', GA_EventValues.HeaderLogo, headerLogoLink,openMethod);
		});
		// my account click
		accountLink.addEventListener('click', (event: MouseEvent) => {
			// event.preventDefault();
			AnalyticInteractions.headerEvent(GA_eventActions.click, 'Header My Account Link', GA_EventValues.HeaderMyAccount, accountLink);
		});
		mobileAccountLink?.addEventListener('click', (event: MouseEvent) => {
			// event.preventDefault();
			AnalyticInteractions.headerEvent(GA_eventActions.click, 'Header My Account Link', GA_EventValues.MobileMyAccount, mobileAccountLink);
		});
	}
}

function setPageEvents() {
	const enableScroll = () => {
		document.querySelector('html').style.removeProperty('overflow');
	};

	const disableScroll = () => {
		document.querySelector('html').style.overflow = 'hidden';
	};

	document.body.addEventListener('disableScroll', disableScroll);
	document.body.addEventListener('enableScroll', enableScroll);

	eventHelpers.initResponsiveLazyImages();
}
