import * as Sentry from "@sentry/browser";

type maskType = {
    [index: number]: string
}
/**
 * Class for handling phone masks
 */
export class PED_PhoneMask {
    private _inputEl: HTMLInputElement;
    private readonly mask: maskType = {
        0: '(',
        3: ') ',
        6: '-'
    };
    constructor(selectorString: string | HTMLInputElement) {
        if (selectorString) {
            if (typeof selectorString === 'string') {
                this._inputEl = document.querySelector(selectorString);
                if (!this._inputEl) {
                    Sentry.captureException(new Error(`Cannot find element provided to phone mask: ${selectorString}`));
                    return;
                }
            }
            else {
                this._inputEl = selectorString;
            }

            this._inputEl.addEventListener('input', this.processMask);
            this._inputEl.addEventListener('blur', this.processMask);
            this.applyMask(0);
        } else {
            Sentry.captureException(`Nothing provided to phone mask initialization.`);
        }
    }
    /**
     * Strip out all special characters from the string, leaving us with just the user input
     * @returns string
     */
    private stripMask = () => {
        return this._inputEl.value.replace(new RegExp(/[^\d]/, 'g'), '');
    };

    private processMask = (e: InputEvent) => {
        let adjustPos = 0;
        const offset = e.inputType === 'insertText' ? 1 : 0;
        if (this._inputEl.value) {
            // if we start with an 11 digit number with a leading 1, strip off the 1
            if (this._inputEl.value.length >= 11 && this._inputEl.value.startsWith('1')) {
                this._inputEl.value = this._inputEl.value.slice(1);
            }
            const cursorPos = this._inputEl.selectionStart;
            adjustPos = this.applyMask(cursorPos - offset);
            this._inputEl.selectionStart = cursorPos + adjustPos;
            this._inputEl.selectionEnd = cursorPos + adjustPos;
        }
    };

    private getCursorOffset(maskChr: string, currentStringLength: number, cursorPosition: number) {
        let offset = 0;
        if (maskChr && currentStringLength === cursorPosition) {
            offset = maskChr.length
        }
        return offset;
    }
    private applyMask = (cursorPos: number) => {
        let newString = '';
        let applied = 0;
        const strippedStr = this.stripMask();

        for (var i = 0; i < strippedStr.length; i++) {
            applied = this.getCursorOffset(this.mask[i], newString.length, cursorPos);
            newString = newString.concat(this.mask[i] || '').concat(strippedStr.charAt(i));
        }
        this._inputEl.value = newString;
        return applied;
    }
};
